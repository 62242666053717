import schemaecrf from "@content/tutorial/images/schema-ecrf.png";
import acceseditionetude from "@content/advanced/images/acces-edition-etude.png";
import ajouterphase from "@content/advanced/images/ajoutphase.png";
import creeretape from "@content/advanced/images/creeretape.png";
import ajoutmodele from "@content/advanced/images/ajoutmodele.png";
import editionetape from "@content/advanced/images/editionetape.png";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function FeatSynopsis() {
  return (
    <PageLayout title="Synopsis de l'étude" roles={["admin"]}>
      <p>
        Le synopsis correspond au <b>CRF</b> de votre étude, il sera composé de{" "}
        <b>phases</b> et d’<b>étapes</b>:
        <li>
          <b>Phase : </b>période spécifique de l’étude pouvant contenir une ou
          plusieurs étapes et être répétée
        </li>
        <li>
          <b>Étapes : </b>sous-section de phase qui va pouvoir contenir un
          modèle et être répétée
        </li>
      </p>
      <Section
        id="fonctionnement-ecrf-dotter-science"
        title="Comprendre le fonctionnement d'un eCRF sur Dotter.science"
      >
        <p>
          Sur Dotter.science, l'eCRF est un ensemble de <b>formulaires</b> qui
          s'intègrent à chaque phase de l'étude sous le terme de "
          <b>modèles de formulaires</b>
          ". Ainsi, on peut créer des formulaires types qui pourront servir
          plusieurs fois à différents moments de l'étude sans avoir à les
          recréer à chaque fois.
        </p>
        <p>
          Les formulaires eux, contiennent une succession de questions ou
          mesures que l'on appelle ici "<b>variables</b>". Ces variables, comme
          les formulaires, sont stockées dans une base sous le terme de "
          <b>modèles de variables</b>" et peuvent être réutilisées à plusieurs
          moments de l'étude.
        </p>
        <img
          src={schemaecrf}
          alt="Schéma de l'eCRF"
          className="fullwidth"
          style={{ boxShadow: "none" }}
        />
        <Note type="important">
          Pour construire le synopsis de votre étude, vous devez au préalable
          avoir créé les variables et les modèles de formulaires de votre étude.
          Si ce n'est pas le cas, vous pouvez consulter l'aide sur{" "}
          <a href="/fr/advanced/variables">les variables</a> et{" "}
          <a href="/fr/advanced/models">les modèles de formulaires</a> ou bien
          suivre le tutoriel pas à pas{" "}
          <a href="/fr/tutorial/a-to-z">Concevoir une étude de A à Z</a>.
        </Note>
      </Section>
      <Section
        id="accéder-à-la-modification-du-synopsis-de-l-étude"
        title="Accéder à la modification du synopsis de l'étude"
      >
        <p>
          Pour accéder au synopsis de l'étude, rendez-vous dans{" "}
          <b>Conception</b> puis <b>Édition de l'étude</b>.
        </p>
        <img
          src={acceseditionetude}
          alt="Onglet édition de l'étude"
          className="centered"
        />
      </Section>
      <Section id="créer-une-phase" title="Créer une phase">
        <p>
          Pour créer une phase, il suffit simplement de cliquer sur{" "}
          <b>Ajouter une phase</b> (1).
        </p>
        <p>
          <li>Choisissez un nom pour votre phase (2)</li>
          <li>Choisissez un type de temporalité (3)</li>
          <li>Entrez l'indice de votre phase (4)</li>
          <li>
            Vous pouvez créer si vous le voulez, une phase répétable et indiquez
            son nombre de répétition (5)
          </li>
          <li>Enregistrez vos modifications en cliquant sur "Ajouter" (6)</li>
        </p>
        <img src={ajouterphase} alt="Créer une phase" className="centered" />
      </Section>
      <Section id="créer-une-étape" title="Créer une étape">
        <p>
          Afin de créer une étape, cliquez sur <b>Ajouter une étape</b> et
          attribuez un nom à votre étape.
        </p>
        <img src={creeretape} alt="Créer une étape" className="centered" />
        <p>
          Vous avez alors le choix entre <b>Créer un nouveau modèle</b> (qui
          vous redirigera sur la page d'édition des modèles) et{" "}
          <b>Utiliser un modèle existant</b>.
        </p>
        <img src={ajoutmodele} alt="Choisir un modèle" className="centered" />
        <p>
          Lorsque vous aurez associé un modèle à votre étape, vous aurez un
          aperçu des différentes phases et variables qui le composent. Cliquez
          sur <b>Ajouter</b> (1) pour enregistrer votre nouvelle étape. (Il est
          possible de changer de modèle associé (2) et d'éditer le modèle actuel
          (3)).
        </p>
        <img src={editionetape} alt="Editer l'étape" className="centered" />
      </Section>
    </PageLayout>
  );
}
